"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UspConsentHandler = void 0;
var consentManagementUsp_1 = require("../../modules/consent/consentManagementUsp");
var log_1 = require("../../utils/log");
var ConsentHandler_1 = require("./ConsentHandler");
var UspConsentHandler = /** @class */ (function () {
    function UspConsentHandler() {
        this.done = false;
        this.enabled = true;
        this.missing_consent = true;
    }
    UspConsentHandler.prototype.init = function () {
        if (!ConsentHandler_1.allConsentHandler.country_code ||
            (ConsentHandler_1.allConsentHandler.country_code === 'US' &&
                (!ConsentHandler_1.allConsentHandler.region_code ||
                    ConsentHandler_1.allConsentHandler.region_code === 'CA'))) {
            (0, consentManagementUsp_1.setConsentConfig)({
                usp: {
                    timeout: 50
                }
            });
        }
        else {
            this.setConsentData({
                usPrivacy: '1---'
            });
        }
    };
    UspConsentHandler.prototype.setConsentData = function (consentData) {
        (0, log_1.logInfo)("UspcConsentHandler: consentData", consentData);
        this.consentData = consentData;
        this.done = true;
        this.checkIfMissing();
        ConsentHandler_1.allConsentHandler.setHandlerReady();
    };
    UspConsentHandler.prototype.getUsPrivacy = function () {
        var _a;
        return ((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.usPrivacy) || '';
    };
    UspConsentHandler.prototype.checkIfMissing = function () {
        var _a;
        return this.missing_consent = !!(!this.consentData || ((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.usPrivacy) === '');
    };
    UspConsentHandler.prototype.loadIfMissing = function (cb) {
        (0, consentManagementUsp_1.loadIfMissing)(cb);
    };
    return UspConsentHandler;
}());
exports.UspConsentHandler = UspConsentHandler;
