"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GdprConsentHandler = void 0;
var consentManagementGdpr_1 = require("../../modules/consent/consentManagementGdpr");
var gdprEnforcement_1 = require("../../modules/consent/gdprEnforcement");
var log_1 = require("../../utils/log");
var ConsentHandler_1 = require("./ConsentHandler");
var GdprConsentHandler = /** @class */ (function () {
    function GdprConsentHandler() {
        this.done = false;
        this.enabled = true;
        this.missing_consent = true;
    }
    GdprConsentHandler.prototype.init = function () {
        if (!ConsentHandler_1.allConsentHandler.country_code ||
            ConsentHandler_1.allConsentHandler.country_code === 'EU') {
            (0, consentManagementGdpr_1.setConsentConfig)({
                gdpr: {
                    timeout: 10000,
                    actionTimeout: 10000,
                    defaultGdprScope: true
                }
            });
        }
        else {
            this.setConsentData({
                apiVersion: 2,
                gdprApplies: false,
                consentString: ''
            });
        }
    };
    GdprConsentHandler.prototype.setConsentData = function (consentData) {
        (0, log_1.logInfo)("GdprConsentHandler: consentData", consentData);
        this.consentData = consentData;
        this.done = true;
        this.checkIfMissing();
        ConsentHandler_1.allConsentHandler.setHandlerReady();
    };
    GdprConsentHandler.prototype.getGdprApplies = function () {
        var _a;
        return ((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.gdprApplies) || false;
    };
    GdprConsentHandler.prototype.getGdprString = function () {
        var _a;
        return ((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.consentString) || '';
    };
    GdprConsentHandler.prototype.isActivityAllowed = function (activity, params) {
        if (!Object.prototype.hasOwnProperty.call(gdprEnforcement_1.ruleFunctions, activity))
            return true;
        return gdprEnforcement_1.ruleFunctions[activity](params);
    };
    GdprConsentHandler.prototype.checkIfMissing = function () {
        var _a, _b;
        return this.missing_consent = !!(!this.consentData || (((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.gdprApplies) && !((_b = this.consentData) === null || _b === void 0 ? void 0 : _b.consentString)));
    };
    GdprConsentHandler.prototype.loadIfMissing = function (cb) {
        (0, consentManagementGdpr_1.loadIfMissing)(cb);
    };
    return GdprConsentHandler;
}());
exports.GdprConsentHandler = GdprConsentHandler;
