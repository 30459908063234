"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ruleFunctions = exports.setEnforcementConfig = exports.getGvlid = void 0;
var log_1 = require("../../utils/log");
var utils_1 = require("../../utils/utils");
var gvlidRegistry_1 = require("./gvlidRegistry");
var ConsentHandler_1 = require("../../classes/consent/ConsentHandler");
var moduleTypes_1 = require("./moduleTypes");
var activities_1 = require("../../modules/consent/activities");
/**
 * This module gives publishers extra set of features to enforce individual purposes of TCF v2
 */
var STRICT_STORAGE_ENFORCEMENT = 'strictStorageEnforcement';
var ACTIVE_RULES = {
    purpose: {},
    feature: {}
};
var CONSENT_PATHS = {
    purpose: 'purpose.consents',
    feature: 'specialFeatureOptins'
};
var CONFIGURABLE_RULES = {
    storage: {
        type: 'purpose',
        default: {
            purpose: 'storage',
            enforcePurpose: true,
            enforceVendor: true,
            vendorExceptions: []
        },
        id: 1,
    },
    basicAds: {
        type: 'purpose',
        id: 2,
        default: {
            purpose: 'basicAds',
            enforcePurpose: true,
            enforceVendor: true,
            vendorExceptions: []
        }
    },
    personalizedAds: {
        type: 'purpose',
        id: 4,
        default: {
            purpose: 'personalizedAds',
            enforcePurpose: true,
            enforceVendor: true,
            vendorExceptions: []
        }
    },
    measurement: {
        type: 'purpose',
        id: 7,
        default: {}
    },
    transmitPreciseGeo: {
        type: 'feature',
        id: 1,
        default: {
            purpose: 'transmitPreciseGeo',
            enforcePurpose: true,
            enforceVendor: false,
            vendorExceptions: []
        }
    },
};
var VENDORLESS_GVLID = {};
var strictStorageEnforcement = false;
// in JS we do not have access to the GVL; assume that everyone declares legitimate interest for basic ads
var LI_PURPOSES = [2];
/**
 * Retrieve a module's GVL ID.
 */
function getGvlid(moduleType, moduleName, fallbackFn) {
    if (moduleName) {
        if (moduleType === moduleTypes_1.MODULE_TYPE_CORE) { // MODULE_TYPE_PREBID
            return VENDORLESS_GVLID;
        }
        else {
            var gvlid = gvlidRegistry_1.GDPR_GVLIDS.get(moduleName).gvlid;
            if (gvlid == null && fallbackFn) {
                gvlid = fallbackFn();
            }
            return gvlid || null;
        }
    }
    return null;
}
exports.getGvlid = getGvlid;
/**
 * Retrieve GVL IDs that are dynamically set on analytics adapters.
 */
// export function getGvlidFromAnalyticsAdapter(code, config) {
//     const adapter = adapterManager.getAnalyticsAdapter(code);
//     return ((gvlid) => {
//         if (typeof gvlid !== 'function') return gvlid;
//         try {
//             return gvlid.call(adapter.adapter, config);
//         } catch (e) {
//             logError(`Error invoking ${code} adapter.gvlid()`, e);
//         }
//     })(adapter?.adapter?.gvlid);
// }
function shouldEnforce(consentData, purpose, name) {
    if (consentData == null && ConsentHandler_1.gdprConsentHandler.enabled) {
        // there is no consent data, but the GDPR module has been installed and configured
        // NOTE: this check is not foolproof, as when Prebid first loads, enforcement hooks have not been attached yet
        // This piece of code would not run at all, and `gdprDataHandler.enabled` would be false, until the first
        // `setConfig({consentManagement})`
        (0, log_1.logWarn)("Attempting operation that requires purpose ".concat(purpose, " consent while consent data is not available").concat(name ? " (module: ".concat(name, ")") : '', ". Assuming no consent was given."));
        return true;
    }
    return consentData && consentData.gdprApplies;
}
function getConsent(consentData, type, id, gvlId) {
    var purpose = !!(0, utils_1.deepAccess)(consentData, "vendorData.".concat(CONSENT_PATHS[type], ".").concat(id));
    var vendor = !!(0, utils_1.deepAccess)(consentData, "vendorData.vendor.consents.".concat(gvlId));
    if (type === 'purpose' && LI_PURPOSES.includes(id)) {
        purpose || (purpose = !!(0, utils_1.deepAccess)(consentData, "vendorData.purpose.legitimateInterests.".concat(id)));
        vendor || (vendor = !!(0, utils_1.deepAccess)(consentData, "vendorData.vendor.legitimateInterests.".concat(gvlId)));
    }
    return { purpose: purpose, vendor: vendor };
}
/**
 * This function takes in a rule and consentData and validates against the consentData provided. Depending on what it returns,
 * the caller may decide to suppress a TCF-sensitive activity.
 * @param {Object} rule - enforcement rules set in config
 * @param {Object} consentData - gdpr consent data
 * @param {string=} currentModule - Bidder code of the current module
 * @param {number=} gvlId - GVL ID for the module
 * @returns {boolean}
 */
function validateRules(rule, consentData, currentModule, gvlId) {
    var ruleOptions = CONFIGURABLE_RULES[rule.purpose];
    // return 'true' if vendor present in 'vendorExceptions'
    if ((rule.vendorExceptions || []).includes(currentModule)) {
        return true;
    }
    var vendorConsentRequred = rule.enforceVendor && !((gvlId === VENDORLESS_GVLID || (rule.softVendorExceptions || []).includes(currentModule)));
    var _a = getConsent(consentData, ruleOptions.type, ruleOptions.id, gvlId), purpose = _a.purpose, vendor = _a.vendor;
    return (!rule.enforcePurpose || purpose) && (!vendorConsentRequred || vendor);
}
function gdprRule(purposeNo, checkConsent, gvlidFallback) {
    if (gvlidFallback === void 0) { gvlidFallback = function (params) { return params ? null : null; }; }
    return function (params) {
        var allow = true;
        var consentData = ConsentHandler_1.gdprConsentHandler.consentData;
        var modName = params['componentName'];
        if (shouldEnforce(consentData, purposeNo, modName)) {
            var gvlid = params['gvlid'] || getGvlid(params['componentType'], modName, gvlidFallback(params));
            allow = !!checkConsent(consentData, modName, gvlid);
        }
        return allow;
    };
}
function singlePurposeGdprRule(purposeNo, gvlidFallback) {
    if (gvlidFallback === void 0) { gvlidFallback = function () { return null; }; }
    return gdprRule(purposeNo, function (cd, modName, gvlid) { return !!validateRules(ACTIVE_RULES.purpose[purposeNo], cd, modName, gvlid); }, gvlidFallback);
}
function exceptCoreModules(ruleFn) {
    return function (params) {
        if (params['componentType'] === moduleTypes_1.MODULE_TYPE_CORE) {
            // TODO: this special case is for the PBS adapter (componentType is 'core')
            // we should check for generic purpose 2 consent & vendor consent based on the PBS vendor's GVL ID;
            // that is, however, a breaking change and skipped for now
            return true;
        }
        return ruleFn(params);
    };
}
var accessDeviceRule = (function (rule) {
    return function (params) {
        // for vendorless (core) storage, do not enforce rules unless strictStorageEnforcement is set
        if (params['componentType'] === moduleTypes_1.MODULE_TYPE_CORE && !strictStorageEnforcement)
            return true;
        return rule(params);
    };
})(singlePurposeGdprRule(1));
var syncUserRule = singlePurposeGdprRule(1);
var enrichEidsRule = singlePurposeGdprRule(1);
var fetchBidsRule = exceptCoreModules(singlePurposeGdprRule(2));
// export const reportAnalyticsRule = singlePurposeGdprRule(7, analyticsBlocked, (params) => getGvlidFromAnalyticsAdapter(params[ACTIVITY_PARAM_COMPONENT_NAME], params[ACTIVITY_PARAM_ANL_CONFIG]));
var ufpdRule = singlePurposeGdprRule(4);
var transmitEidsRule = exceptCoreModules((function () {
    // Transmit EID special case:
    // by default, legal basis or vendor exceptions for any purpose between 2 and 10
    // (but disregarding enforcePurpose and enforceVendor config) is enough to allow EIDs through
    function check2to10Consent(consentData, modName, gvlId) {
        var _a, _b, _c, _d;
        for (var pno = 2; pno <= 10; pno++) {
            if ((_b = (_a = ACTIVE_RULES.purpose[pno]) === null || _a === void 0 ? void 0 : _a.vendorExceptions) === null || _b === void 0 ? void 0 : _b.includes(modName)) {
                return true;
            }
            var _e = getConsent(consentData, 'purpose', pno, gvlId), purpose = _e.purpose, vendor = _e.vendor;
            if (purpose && (vendor || ((_d = (_c = ACTIVE_RULES.purpose[pno]) === null || _c === void 0 ? void 0 : _c.softVendorExceptions) === null || _d === void 0 ? void 0 : _d.includes(modName)))) {
                return true;
            }
        }
        return false;
    }
    var defaultBehavior = gdprRule('2-10', check2to10Consent);
    var p4Behavior = singlePurposeGdprRule(4);
    return function () {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var fn = ((_a = ACTIVE_RULES.purpose[4]) === null || _a === void 0 ? void 0 : _a.eidsRequireP4Consent) ? p4Behavior : defaultBehavior;
        return fn.apply(this, args);
    };
})());
var transmitPreciseGeoRule = gdprRule('Special Feature 1', function (cd, modName, gvlId) { return validateRules(ACTIVE_RULES.feature[1], cd, modName, gvlId); });
/**
 * A configuration function that initializes some module variables, as well as adds hooks
 * @param {Object} config - GDPR enforcement config object
 */
function setEnforcementConfig(config) {
    var rules = (0, utils_1.deepAccess)(config, 'gdpr.rules');
    if (!rules) {
        (0, log_1.logWarn)('TCF2: enforcing P1 and P2 by default');
    }
    rules = Object.fromEntries((rules || []).map(function (r) { return [r.purpose, r]; }));
    strictStorageEnforcement = !!(0, utils_1.deepAccess)(config, STRICT_STORAGE_ENFORCEMENT);
    Object.entries(CONFIGURABLE_RULES).forEach(function (_a) {
        var _b;
        var name = _a[0], opts = _a[1];
        ACTIVE_RULES[opts.type][opts.id] = (_b = rules[name]) !== null && _b !== void 0 ? _b : opts.default;
    });
}
exports.setEnforcementConfig = setEnforcementConfig;
exports.ruleFunctions = (_a = {},
    _a[activities_1.ACTIVITY_ACCESS_DEVICE] = accessDeviceRule,
    _a[activities_1.ACTIVITY_SYNC_USER] = syncUserRule,
    _a[activities_1.ACTIVITY_ENRICH_EIDS] = enrichEidsRule,
    _a[activities_1.ACTIVITY_FETCH_BIDS] = fetchBidsRule,
    _a[activities_1.ACTIVITY_ENRICH_UFPD] = ufpdRule,
    _a[activities_1.ACTIVITY_TRANSMIT_EIDS] = transmitEidsRule,
    _a[activities_1.ACTIVITY_TRANSMIT_PRECISE_GEO] = transmitPreciseGeoRule,
    _a);
