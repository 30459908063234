"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SID_MAPPING = exports.NORMALIZATIONS = exports.normalizer = void 0;
var utils_1 = require("../../utils/utils");
var FIELDS = {
    Version: 0,
    Gpc: 0,
    SharingNotice: 0,
    SaleOptOutNotice: 0,
    SharingOptOutNotice: 0,
    TargetedAdvertisingOptOutNotice: 0,
    SensitiveDataProcessingOptOutNotice: 0,
    SensitiveDataLimitUseNotice: 0,
    SaleOptOut: 0,
    SharingOptOut: 0,
    TargetedAdvertisingOptOut: 0,
    SensitiveDataProcessing: 12,
    KnownChildSensitiveDataConsents: 2,
    PersonalDataConsents: 0,
    MspaCoveredTransaction: 0,
    MspaOptOutOptionMode: 0,
    MspaServiceProviderMode: 0,
};
/**
 * Generate a normalization function for converting US state strings to the usnat format.
 *
 * Scalar fields are copied over if they exist in the input (state) data, or set to null otherwise.
 * List fields are also copied, but forced to the "correct" length (by truncating or padding with nulls);
 * additionally, elements within them can be moved around using the `move` argument.
 *
 * @param {Array[String]} nullify? list of fields to force to null
 * @param {{}} move? Map from list field name to an index remapping for elements within that field (using 1 as the first index).
 *       For example, {SensitiveDataProcessing: {1: 2, 2: [1, 3]}} means "rearrange SensitiveDataProcessing by moving
 *       the first element to the second position, and the second element to both the first and third position."
 * @param {({}, {}) => void} fn? an optional function to run once all the processing described above is complete;
 *       it's passed two arguments, the original (state) data, and its normalized (usnat) version.
 * @param fields
 * @returns {function({}): {}}
 */
function normalizer(_a, fields) {
    var _b = _a.nullify, nullify = _b === void 0 ? [] : _b, _c = _a.move, move = _c === void 0 ? {} : _c, fn = _a.fn;
    if (fields === void 0) { fields = FIELDS; }
    move = Object.fromEntries(Object.entries(move).map(function (_a) {
        var k = _a[0], map = _a[1];
        return [k,
            Object.fromEntries(Object.entries(map)
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return [k, Array.isArray(v) ? v : [v]];
            })
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return [Number(k) - 1, Array.isArray(v) ? v.map(function (el) { return --el; }) : v];
            }))];
    }));
    return function (cd) {
        var norm = Object.fromEntries(Object.entries(fields)
            .map(function (_a) {
            var field = _a[0], len = _a[1];
            var val = null;
            if (len > 0) {
                val = Array(len).fill(null);
                if (Array.isArray(cd[field])) {
                    var remap_1 = move[field] || {};
                    var done_1 = [];
                    cd[field].forEach(function (el, i) {
                        var _a = Object.prototype.hasOwnProperty.call(remap_1, i) ? [remap_1[i], true] : [[i], false], dest = _a[0], moved = _a[1];
                        dest.forEach(function (d) {
                            if (d < len && !done_1.includes(d)) {
                                val[d] = el;
                                moved && done_1.push(d);
                            }
                        });
                    });
                }
            }
            else if (cd[field] != null) {
                val = Array.isArray(cd[field]) ? null : cd[field];
            }
            return [field, val];
        }));
        nullify.forEach(function (path) { return (0, utils_1.deepSetValue)(norm, path, null); });
        fn && fn(cd, norm);
        return norm;
    };
}
exports.normalizer = normalizer;
function scalarMinorsAreChildren(original, normalized) {
    normalized.KnownChildSensitiveDataConsents = original.KnownChildSensitiveDataConsents === 0 ? [0, 0] : [1, 1];
}
exports.NORMALIZATIONS = {
    // normalization rules - convert state consent into usnat consent
    // https://docs.prebid.org/features/mspa-usnat.html
    7: function (consent) { return consent; },
    8: normalizer({
        move: {
            SensitiveDataProcessing: {
                1: 9,
                2: 10,
                3: 8,
                4: [1, 2],
                5: 12,
                8: 3,
                9: 4,
            }
        },
        fn: function (original, normalized) {
            if (original.KnownChildSensitiveDataConsents.some(function (el) { return el !== 0; })) {
                normalized.KnownChildSensitiveDataConsents = [1, 1];
            }
        }
    }),
    9: normalizer({ fn: scalarMinorsAreChildren }),
    10: normalizer({ fn: scalarMinorsAreChildren }),
    11: normalizer({
        move: {
            SensitiveDataProcessing: {
                3: 4,
                4: 5,
                5: 3,
            }
        },
        fn: scalarMinorsAreChildren
    }),
    12: normalizer({
        fn: function (original, normalized) {
            var cc = original.KnownChildSensitiveDataConsents;
            var repl;
            if (!cc.some(function (el) { return el !== 0; })) {
                repl = [0, 0];
            }
            else if (cc[1] === 2 && cc[2] === 2) {
                repl = [2, 1];
            }
            else {
                repl = [1, 1];
            }
            normalized.KnownChildSensitiveDataConsents = repl;
        }
    })
};
exports.DEFAULT_SID_MAPPING = {
    8: 'usca',
    9: 'usva',
    10: 'usco',
    11: 'usut',
    12: 'usct'
};
