"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalIdentityAdapeterHandler = void 0;
var ConsentHandler_1 = require("../consent/ConsentHandler");
var activities_1 = require("../../modules/consent/activities");
var IdentityAdapeterHandler = /** @class */ (function () {
    function IdentityAdapeterHandler() {
    }
    IdentityAdapeterHandler.prototype.getUserIdsAsEids = function () {
        var _a, _b;
        return (ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ENRICH_EIDS, { gvlid: {} })) ? ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getUserIdsAsEids) && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getUserIdsAsEids()) : [];
    };
    IdentityAdapeterHandler.prototype.getUserIds = function () {
        var _a, _b;
        return (ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ENRICH_EIDS, { gvlid: {} })) ? ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getUserIdsAsEids) && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getUserIdsAsEids()) : {};
    };
    return IdentityAdapeterHandler;
}());
exports.globalIdentityAdapeterHandler = new IdentityAdapeterHandler();
