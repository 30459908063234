"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GppConsentHandler = void 0;
var log_1 = require("../../utils/log");
var consentManagementGpp_1 = require("../../modules/consent/consentManagementGpp");
var activityControls_1 = require("../../modules/consent/activityControls");
var gppControl_usstates_1 = require("../../modules/consent/gppControl_usstates");
var ConsentHandler_1 = require("./ConsentHandler");
var __1 = require("../..");
var GppConsentHandler = /** @class */ (function () {
    function GppConsentHandler() {
        this.done = false;
        this.enabled = true;
        this.missing_consent = true;
    }
    GppConsentHandler.prototype.init = function () {
        if (!ConsentHandler_1.allConsentHandler.country_code ||
            // allConsentHandler.country_code === 'EU' || 
            // allConsentHandler.country_code === 'CA' ||
            (ConsentHandler_1.allConsentHandler.country_code === 'US' &&
                (!ConsentHandler_1.allConsentHandler.region_code ||
                    ConsentHandler_1.US_REGIONS.includes(ConsentHandler_1.allConsentHandler.region_code)))) {
            (0, consentManagementGpp_1.setConsentConfig)({
                gpp: {
                    timeout: 10000
                }
            });
        }
        else {
            this.setConsentData({
                gppString: '',
                applicableSections: [],
                parsedSections: {}
            });
        }
    };
    GppConsentHandler.prototype.setConsentData = function (consentData) {
        (0, log_1.logInfo)("GppConsentHandler setConsentData: consentData", consentData);
        this.consentData = consentData;
        this.done = true;
        this.checkIfMissing();
        ConsentHandler_1.allConsentHandler.setHandlerReady();
    };
    GppConsentHandler.prototype.isActivityAllowed = function (activity) {
        var _a, _b, _c, _d, _e;
        if (!Object.prototype.hasOwnProperty.call(activityControls_1.CONSENT_RULES, activity))
            return true;
        var consentData = this.consentData;
        var applicableSections = (consentData === null || consentData === void 0 ? void 0 : consentData.applicableSections) || [];
        var sid_mapping = ((((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.BigcrunchCmpTag) === null || _a === void 0 ? void 0 : _a.loaded) ||
            ((_b = __1.globalSettings.cmp[ConsentHandler_1.allConsentHandler.country_code]) === null || _b === void 0 ? void 0 : _b.enabled)) &&
            ((_c = __1.globalSettings.cmp[ConsentHandler_1.allConsentHandler.country_code]) === null || _c === void 0 ? void 0 : _c.sid_mapping) || gppControl_usstates_1.DEFAULT_SID_MAPPING);
        // const sid_mapping = globalSettings?.cmp[allConsentHandler.country_code]?.enabled && globalSettings?.cmp[allConsentHandler.country_code]?.sid_mapping || DEFAULT_SID_MAPPING
        var isAllowed = true;
        for (var _i = 0, applicableSections_1 = applicableSections; _i < applicableSections_1.length; _i++) {
            var sid = applicableSections_1[_i];
            var apiConsentData = void 0;
            if (sid === 7) {
                var api = sid_mapping[sid];
                apiConsentData = (_d = consentData === null || consentData === void 0 ? void 0 : consentData.parsedSections) === null || _d === void 0 ? void 0 : _d[api];
            }
            else {
                var logger = (0, log_1.prefixLog)("Cannot set up MSPA controls for SID ".concat(sid, ":"));
                if (!Object.prototype.hasOwnProperty.call(gppControl_usstates_1.NORMALIZATIONS, sid)) {
                    logger.logError("no normalization rules are known for SID ".concat(sid));
                    return;
                }
                var api = sid_mapping[sid];
                if (typeof api !== 'string') {
                    logger.logError("cannot determine GPP section name");
                    return;
                }
                var normalizeConsent = gppControl_usstates_1.NORMALIZATIONS[sid];
                apiConsentData = normalizeConsent((0, activityControls_1.flatSection)((_e = consentData === null || consentData === void 0 ? void 0 : consentData.parsedSections) === null || _e === void 0 ? void 0 : _e[api]));
            }
            isAllowed = !activityControls_1.CONSENT_RULES[activity](apiConsentData);
            if (!isAllowed) {
                break;
            }
        }
        return isAllowed;
    };
    GppConsentHandler.prototype.getGppString = function () {
        var _a;
        return ((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.gppString) || '';
    };
    GppConsentHandler.prototype.getApplicableSections = function () {
        var _a;
        return ((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.applicableSections) || [];
    };
    GppConsentHandler.prototype.checkIfMissing = function () {
        var _a, _b;
        return this.missing_consent = !!(!this.consentData || (((_a = this.consentData) === null || _a === void 0 ? void 0 : _a.applicableSections.length) && !((_b = this.consentData) === null || _b === void 0 ? void 0 : _b.gppString)));
    };
    GppConsentHandler.prototype.loadIfMissing = function (cb) {
        (0, consentManagementGpp_1.loadIfMissing)(cb);
    };
    return GppConsentHandler;
}());
exports.GppConsentHandler = GppConsentHandler;
