"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setConsentConfig = exports.loadIfMissing = void 0;
/**
 * This module adds GDPR consentManagement support.  It interacts with
 * supported CMPs (Consent Management Platforms) to grab the user's consent information
 * and make it available for any GDPR supported adapters to read/pass this information to
 * their system.
 */
var log_1 = require("../../utils/log");
var utils_1 = require("../../utils/utils");
var cmpClient_1 = require("./cmpClient");
var ConsentHandler_1 = require("../../classes/consent/ConsentHandler");
var gdprEnforcement_1 = require("./gdprEnforcement");
var DEFAULT_CONSENT_TIMEOUT = 10000;
var CMP_VERSION = 2;
var consentTimeout;
var actionTimeout;
var consentData;
var gdprScope;
/**
 * Look up consent data and store it in the `consentData` global as well as `adapterManager.js`' gdprDataHandler.
 *
 * @param cb A callback that takes: a boolean that is true if the auction should be canceled; an error message and extra
 * error arguments that will be undefined if there's no error.
 */
function loadConsentData(cb) {
    var isDone = false;
    var timer = null;
    var provisionalConsent = null;
    var cmpLoaded = false;
    var fail_count = 0;
    function resetTimeout(timeout) {
        if (timer != null) {
            clearTimeout(timer);
        }
        if (!isDone && timeout != null) {
            if (timeout === 0) {
                onTimeout();
            }
            else {
                timer = setTimeout(onTimeout, timeout);
            }
        }
    }
    function done(consentData, shouldCancelAuction, errMsg) {
        var extraArgs = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            extraArgs[_i - 3] = arguments[_i];
        }
        resetTimeout(null);
        isDone = true;
        if (errMsg)
            log_1.logWarn.apply(void 0, __spreadArray(["GDPR:", errMsg], extraArgs, false));
        ConsentHandler_1.gdprConsentHandler.setConsentData(consentData);
        if (typeof cb === 'function') {
            cb.apply(void 0, __spreadArray([shouldCancelAuction, errMsg], extraArgs, false));
        }
    }
    var onTimeout = function () {
        var continueToAuction = function (data) {
            done(data, false, "".concat(cmpLoaded ? 'Timeout waiting for user action on CMP' : 'CMP did not load', ", continuing auction..."));
        };
        processCmpData(provisionalConsent, {
            onSuccess: continueToAuction,
            onError: function () { return continueToAuction(storeConsentData(undefined)); },
        });
    };
    var callbacks = {
        onSuccess: function (data) { return done(data, false, ''); },
        onError: function (msg) {
            var extraArgs = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                extraArgs[_i - 1] = arguments[_i];
            }
            if (fail_count < 10) {
                fail_count++;
                setTimeout(function () {
                    lookupIabConsent(callbacks);
                }, 500);
            }
            else {
                done.apply(void 0, __spreadArray([null, true, msg], extraArgs, false));
            }
        },
        onEvent: function (consentData) {
            provisionalConsent = consentData;
            if (cmpLoaded)
                return;
            cmpLoaded = true;
            if (actionTimeout != null) {
                resetTimeout(actionTimeout);
            }
        }
    };
    lookupIabConsent(callbacks);
    if (!(actionTimeout != null && cmpLoaded)) {
        resetTimeout(consentTimeout);
    }
    // add eventListener for event that country code was recieved
    document.addEventListener('BcConsentCountryCode', function (event) {
        if (event.detail !== 'EU' && !isDone) {
            done({
                gdprApplies: false,
                apiVersion: CMP_VERSION
            }, false, '');
        }
    });
}
/**
 * This function handles interacting with an IAB compliant CMP to obtain the consent information of the user.
 * Given the async nature of the CMP's API, we pass in acting success/error callback functions to exit this function
 * based on the appropriate result.
 * @param {function({})} onSuccess acts as a success callback when CMP returns a value; pass along consentObjectfrom CMP
 * @param {function(string, ...{}?)} onError acts as an error callback while interacting with CMP; pass along an error message (string) and any extra error arguments (purely for logging)
 */
function lookupIabConsent(_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, onEvent = _a.onEvent;
    function cmpResponseCallback(tcfData, success) {
        (0, log_1.logInfo)('GDPR: Received a response from CMP', tcfData);
        if (success) {
            onEvent(tcfData);
            if (tcfData.gdprApplies === false || tcfData.eventStatus === 'tcloaded' || tcfData.eventStatus === 'useractioncomplete') {
                processCmpData(tcfData, { onSuccess: onSuccess, onError: onError });
            }
        }
        else {
            onError('GDPR: CMP unable to register callback function.  Please check CMP setup.');
        }
    }
    var cmp = (0, cmpClient_1.cmpClient)({
        apiName: '__tcfapi',
        apiVersion: CMP_VERSION,
        apiArgs: ['command', 'version', 'callback', 'parameter'],
    });
    if (!cmp) {
        return onError('TCF2 CMP not found.');
    }
    if (cmp.isDirect) {
        (0, log_1.logInfo)('GDPR: Detected CMP API is directly accessible, calling it now...');
    }
    cmp({
        command: 'addEventListener',
        callback: cmpResponseCallback
    });
}
/**
 * Like `loadConsentData`, but cache and re-use previously loaded data.
 * @param cb
 */
function loadIfMissing(cb) {
    if (consentData && consentData.consentString) {
        (0, log_1.logInfo)('GDPR: User consent information already known.  Pulling internally stored information...');
        cb(false);
    }
    else {
        loadConsentData(cb);
    }
}
exports.loadIfMissing = loadIfMissing;
/**
 * This function checks the consent data provided by CMP to ensure it's in an expected state.
 * If it's bad, we call `onError`
 * If it's good, then we store the value and call `onSuccess`
 */
function processCmpData(consentObject, _a) {
    var onSuccess = _a.onSuccess, onError = _a.onError;
    function checkData() {
        // if CMP does not respond with a gdprApplies boolean, use defaultGdprScope (gdprScope)
        var gdprApplies = consentObject && typeof consentObject.gdprApplies === 'boolean' ? consentObject.gdprApplies : gdprScope;
        var tcString = consentObject && consentObject.tcString;
        return !!((typeof gdprApplies !== 'boolean') ||
            (gdprApplies === true && (!tcString || !(0, utils_1.isStr)(tcString))));
    }
    if (checkData()) {
        onError("CMP returned unexpected value during lookup process.", consentObject);
    }
    else {
        onSuccess(storeConsentData(consentObject));
    }
}
/**
 * Stores CMP data locally in module to make information available in adaptermanager.js for later in the auction
 * @param {object} cmpConsentObject required; an object representing user's consent choices (can be undefined in certain use-cases for this function only)
 */
function storeConsentData(cmpConsentObject) {
    consentData = {
        consentString: (cmpConsentObject) ? cmpConsentObject.tcString : undefined,
        vendorData: (cmpConsentObject) || undefined,
        gdprApplies: cmpConsentObject && typeof cmpConsentObject.gdprApplies === 'boolean' ? cmpConsentObject.gdprApplies : gdprScope,
        apiVersion: CMP_VERSION
    };
    if (cmpConsentObject && cmpConsentObject.addtlConsent && (0, utils_1.isStr)(cmpConsentObject.addtlConsent)) {
        consentData.addtlConsent = cmpConsentObject.addtlConsent;
    }
    return consentData;
}
/**
 * A configuration function that initializes some module variables, as well as add a hook into the requestBids function
 * @param {{cmp:string, timeout:number, allowAuctionWithoutConsent:boolean, defaultGdprScope:boolean}} config required; consentManagement module config settings; cmp (string), timeout (int), allowAuctionWithoutConsent (boolean)
 */
function setConsentConfig(config) {
    config = config && config.gdpr;
    if (!config || typeof config !== 'object') {
        (0, log_1.logWarn)('consentManagement (gdpr) config not defined, exiting consent manager');
        return;
    }
    // check if config.timeout is a number
    if ((0, utils_1.isNumber)(config.timeout)) {
        consentTimeout = config.timeout;
    }
    else {
        consentTimeout = DEFAULT_CONSENT_TIMEOUT;
        (0, log_1.logInfo)("GDPR: consentManagement config did not specify timeout. Using system default setting (".concat(DEFAULT_CONSENT_TIMEOUT, ")."));
    }
    actionTimeout = (0, utils_1.isNumber)(config.actionTimeout) ? config.actionTimeout : 1000;
    // if true, then gdprApplies should be set to true
    gdprScope = config.defaultGdprScope === true;
    // set enforcement config
    (0, gdprEnforcement_1.setEnforcementConfig)(config.enforcement || {});
    (0, log_1.logInfo)('GDPR: consentManagement module has been activated...');
    loadConsentData(function () { }); // immediately look up consent data to make it available without requiring an auction
}
exports.setConsentConfig = setConsentConfig;
