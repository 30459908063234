"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoInstance = void 0;
var __1 = require("..");
var log_1 = require("../utils/log");
var utils_1 = require("../utils/utils");
var Analytics_1 = require("./Analytics");
var ima_loaded = false;
var VideoInstance = /** @class */ (function () {
    // Constructor
    function VideoInstance(div_id, slotObj, callback) {
        this.ad_bar_interval = null;
        this.container = null;
        this.video_wrapper = null;
        this.video_player = null;
        this.video_meta = null;
        this.video_title = null;
        this.video_description = null;
        this.div_id = div_id;
        this.video_type = "";
        this.time_stamp = null;
        // Variables for ads (outstream)
        this.ads_manager = null;
        this.ad_container = null;
        this.ad_display_container = null;
        this.ads_loader = null;
        this.ad_started = false;
        this.ad_paused = true;
        this.ad_finished = false;
        this.callback = callback;
        this.muted = true;
        this.deleted = false;
        this.can_play = false;
        this.initialized = false;
        this.ad_bar_interval = null;
        // SVG icons
        this.volume_up_icon = '<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M592 256c0 78.5-37.7 148.2-96 192l28.8 38.4C594.7 433.9 640 350.3 640 256.1l0-.1 0-.1c0-94.2-45.3-177.8-115.2-230.3L496 64c58.3 43.8 96 113.5 96 192zM438.4 371.2l28.8 38.4c46.6-35 76.8-90.8 76.8-153.6s-30.2-118.6-76.8-153.6l-28.8 38.4c35 26.3 57.6 68.1 57.6 115.2s-22.6 88.9-57.6 115.2zM400 256c0 15.7-7.5 29.6-19.2 38.4l28.8 38.4C432.9 315.3 448 287.4 448 256s-15.1-59.3-38.4-76.8l-28.8 38.4c11.7 8.8 19.2 22.7 19.2 38.4zM0 160V352H128L272 480h48V32H272L128 160H0z"></path></svg>';
        this.volume_mute_icon = '<svg class="mute_icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M0 160V352H128L272 480h48V32H272L128 160H0zm441 23l-17-17L390.1 200l17 17 39 39-39 39-17 17L424 345.9l17-17 39-39 39 39 17 17L569.9 312l-17-17-39-39 39-39 17-17L536 166.1l-17 17-39 39-39-39z"></path></svg>';
        this.play_icon = '<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M384 256L0 32V480L384 256z"></path></svg>';
        this.pause_icon = '<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M128 64H0V448H128V64zm192 0H192V448H320V64z"></path></svg>';
        this.close_icon = '<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path></svg>';
        // Slot Object
        this.slotObj = slotObj;
        // Set the settings
        this.settings = slotObj.outstream || {};
        // Create a new video player
        this.createVideoPlayer();
    }
    // Create a new video player
    VideoInstance.prototype.createVideoPlayer = function () {
        var _this = this;
        // Get the container
        this.container = document.getElementById(this.div_id);
        // If container doesn't exist, bail
        if (!this.container)
            return;
        if (this.settings.expand) {
            this.container.classList.add('expandable');
            this.container.classList.add('collapsed');
        }
        if (document.getElementById(this.div_id + "_video_wrapper"))
            return;
        var video_wrapper = document.createElement("div");
        video_wrapper.classList.add("bigcrunch-video-wrapper");
        video_wrapper.setAttribute("id", this.div_id + "_video_wrapper");
        this.video_wrapper = video_wrapper;
        if (this.slotObj.sticky.enabled) {
            // add classes to element
            this.container.classList.add('bigcrunch-sticky-outstream-wrapper');
        }
        else {
            // add classes to element
            this.container.classList.add('bigcrunch-outstream-wrapper');
        }
        // Set autoplay
        this.autoplay = true;
        // If this video already exists, bail
        // if(document.getElementById(this.div_id + "_video")) return;
        // Create the video element
        var video = document.createElement("video");
        video.setAttribute("id", this.div_id + "_video");
        video.setAttribute("autoplay", "".concat(this.autoplay));
        video.setAttribute("playsinline", "");
        video.setAttribute("preload", "none");
        video.setAttribute("muted", "true");
        video.setAttribute("oncanplay", "this.muted=true");
        video.style.width = '100%';
        video.style.height = '100%';
        this.video_player = video;
        // mute the video
        this.muted = true;
        // Add the video to the video wrapper
        this.video_wrapper.appendChild(this.video_player);
        // Add video to the page
        this.container.appendChild(this.video_wrapper);
        // Default to outstream
        this.video_type = "outstream";
        // Add the ad-container right after the video player
        this.ad_container = document.createElement("div");
        this.ad_container.setAttribute("id", "ad-container");
        // Add size to ad-container
        this.ad_container.style.width = '100%';
        this.ad_container.style.height = '100%';
        // Add the ad-container right after the video player
        this.video_player.parentNode.insertBefore(this.ad_container, this.video_player.nextSibling);
        // Create video meta
        var video_meta = document.createElement("div");
        video_meta.classList.add("bigcrunch-video-meta");
        this.video_meta = video_meta;
        // Create video title
        var video_title = document.createElement("div");
        video_title.classList.add("bigcrunch-video-title");
        this.video_title = video_title;
        this.video_meta.appendChild(video_title);
        // Create video description
        var video_description = document.createElement("div");
        video_description.classList.add("bigcrunch-video-description");
        this.video_description = video_description;
        this.video_meta.appendChild(video_description);
        // Add video meta to the page
        this.container.appendChild(video_meta);
        if (!ima_loaded) {
            // Load the IMA SDK
            this.loadScript("https://imasdk.googleapis.com/js/sdkloader/ima3.js") // https://imasdk.googleapis.com/js/sdkloader/ima3_debug.js
                .then(function () {
                ima_loaded = true;
                _this.initializeIma();
            })
                .catch(function (error) {
                (0, log_1.logInfo)("Error loading IMA SDK", error);
            });
        }
        else {
            this.initializeIma();
        }
    };
    // Initialize ads for outstream player
    VideoInstance.prototype.initializeIma = function () {
        // Create the ad display container
        this.ad_display_container = new globalThis.google.ima.AdDisplayContainer(this.ad_container, this.video_player);
        this.ads_loader = new globalThis.google.ima.AdsLoader(this.ad_display_container);
        this.ads_loader.getSettings().setAutoPlayAdBreaks(true);
        this.ads_loader.getSettings().setDisableCustomPlaybackForIOS10Plus(true);
        // Listen and respond to ads_loader events
        this.ads_loader.addEventListener(globalThis.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, this.adsManagerLoaded.bind(this), false);
        // Listen and respond to ads_loader errors
        this.ads_loader.addEventListener(globalThis.google.ima.AdErrorEvent.Type.AD_ERROR, this.adError.bind(this), false);
        this.imaRequestAds();
    };
    VideoInstance.prototype.imaRequestAds = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        // Request video ads.
        var ads_request = new globalThis.google.ima.AdsRequest();
        if ((_b = (_a = this.slotObj.winningBid) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.adm) {
            switch (this.isUrlOrVastTag(((_d = (_c = this.slotObj.winningBid) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.adm) || '')) {
                case 'URL':
                    ads_request.adTagUrl = (_f = (_e = this.slotObj.winningBid) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.adm;
                    break;
                case 'VAST':
                    ads_request.adsResponse = (_h = (_g = this.slotObj.winningBid) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.adm;
                    break;
                default:
                    this.adError('Invalid ad tag or VAST response');
                    break;
            }
        }
        else {
            this.adError('Invalid ad tag or VAST response');
        }
        // Specify the linear and nonlinear slot sizes. This helps the SDK to
        // select the correct creative if multiple are returned.
        ads_request.linearAdSlotWidth = (_j = this.slotObj.winningBid) === null || _j === void 0 ? void 0 : _j.data.w;
        ads_request.linearAdSlotHeight = (_l = (_k = this.slotObj.winningBid) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.h;
        ads_request.setAdWillPlayMuted(true);
        ads_request.setAdWillAutoPlay(true);
        // Pass the request to the ads_loader to request ads
        this.ads_loader.requestAds(ads_request);
    };
    // When ads manager is loaded
    VideoInstance.prototype.adsManagerLoaded = function (adsManagerLoadedEvent) {
        // Instantiate the AdsManager from the ads_loader response and pass it the video element
        this.ads_manager = adsManagerLoadedEvent.getAdsManager(this.video_player);
        this.ads_manager.setVolume(0);
        var ad_events = [
            globalThis.google.ima.AdEvent.Type.AD_CAN_PLAY,
            globalThis.google.ima.AdEvent.Type.LOADED,
            globalThis.google.ima.AdEvent.Type.STARTED,
            globalThis.google.ima.AdEvent.Type.PAUSED,
            globalThis.google.ima.AdEvent.Type.RESUMED,
            globalThis.google.ima.AdEvent.Type.IMPRESSION,
            globalThis.google.ima.AdEvent.Type.DURATION_CHANGE,
            globalThis.google.ima.AdEvent.Type.AD_PROGRESS,
            globalThis.google.ima.AdEvent.Type.FIRST_QUARTILE,
            globalThis.google.ima.AdEvent.Type.MIDPOINT,
            globalThis.google.ima.AdEvent.Type.THIRD_QUARTILE,
            globalThis.google.ima.AdEvent.Type.COMPLETE,
            globalThis.google.ima.AdEvent.Type.CLICK,
            globalThis.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
            globalThis.google.ima.AdEvent.Type.AD_METADATA,
        ];
        for (var event_1 in ad_events) {
            this.ads_manager.addEventListener(ad_events[event_1], this.initializeAdEvents.bind(this));
        }
        this.loadAds();
    };
    // Start loading ads
    VideoInstance.prototype.loadAds = function () {
        // this.video_player.load();
        // Initialize the container. Must be done via a user action on mobile devices.
        this.ad_display_container.initialize();
        // Calculate the width and height of the video player
        var width = '100%'; // this.slotObj.winningBid.data.w;
        var height = '100%'; // this.slotObj.winningBid.data.h;
        // Initialize the ads manager. Ad rules playlist will start at this time
        try {
            this.ads_manager.init(width, height, globalThis.google.ima.ViewMode.NORMAL);
        }
        catch (adError) {
            // Play the video without ads, if an error occurs
            this.adError("AdsManager could not be started");
        }
    };
    VideoInstance.prototype.playAds = function () {
        if (!this.initialized || !this.can_play)
            return;
        // If video is paused, play it and change the button to pause
        if (this.ad_paused == true) {
            if (this.ad_paused == true &&
                this.ads_manager) {
                if (this.muted) {
                    this.video_player.muted = true;
                }
                if (this.play_button) {
                    // Change the button to pause
                    this.play_button.innerHTML = this.pause_icon;
                }
                // this.video_player.play();
                this.video_player.muted = true;
                if (!this.ad_started) {
                    this.ads_manager.start();
                    this.ads_manager.setVolume(0);
                    this.ad_started = true;
                }
                else if (this.ad_paused) {
                    this.ads_manager.resume();
                }
                this.ad_paused = false;
            }
            // If video is playing, pause it and change the button to play
        }
        else if (this.ad_paused == false) {
            // this.video_player.pause();
            if (this.play_button) {
                // Change the button to play
                this.play_button.innerHTML = this.play_icon;
            }
            if (this.ads_manager) {
                this.ads_manager.pause();
                this.ad_paused = true;
            }
        }
    };
    VideoInstance.prototype.pauseAds = function () {
        if (this.ads_manager &&
            this.ad_started &&
            !this.ad_paused) {
            this.ads_manager.pause();
            this.ad_paused = true;
        }
    };
    // Ad has loaded
    VideoInstance.prototype.initializeAdEvents = function (ad_event) {
        // this.ad_paused = false;
        var video_ad = null;
        var video_title = null;
        var video_description = null;
        switch (ad_event.type) {
            case globalThis.google.ima.AdEvent.Type.AD_CAN_PLAY:
                console.log("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.LOADED:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                this.ad_playing = ad_event.getAd();
                video_ad = ad_event.getAd();
                video_title = video_ad.getTitle();
                video_description = video_ad.getDescription();
                /*
                if(video_title) {
                    this.video_title.textContent = video_title;
                    this.video_title.style.display = 'block';
                }

                if(video_description) {
                    this.video_description.textContent = video_description;
                    this.video_description.style.display = '-webkit-box';
                }

                if(video_title || video_description) {
                    this.video_meta.style.display = 'block';
                }
                */
                try {
                    (0, log_1.logInfo)("VideoPlayer Title", video_title);
                    (0, log_1.logInfo)("VideoPlayer Description", video_description);
                    (0, log_1.logInfo)("VideoPlayer Advertiser", video_ad.getAdvertiserName());
                    (0, log_1.logInfo)("VideoPlayer API", video_ad.getApiFramework());
                }
                catch (e) {
                    (0, log_1.logInfo)("VideoPlayer Error", e);
                }
                // Add the control bar to the video player
                this.addControlBar();
                // Set initialized to true
                this.initialized = true;
                // Set can_play to true
                this.can_play = true;
                // Play the ads
                if (this.slotObj.viewability.inview) {
                    this.playAds();
                }
                break;
            case globalThis.google.ima.AdEvent.Type.STARTED:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                // remove collapsed class
                this.container.classList.remove('collapsed');
                // Set the winning bid to the displayed bid
                this.slotObj.displayedBid = this.slotObj.winningBid;
                // Set the winning bid to null
                this.slotObj.winningBid = null;
                // Send bid analytics
                this.slotObj.displayedBid.sendImpressionAnalytics();
                // Set volume to 0
                this.ads_manager.setVolume(0);
                // Set ad_playing to true
                this.ad_paused = false;
                break;
            case globalThis.google.ima.AdEvent.Type.PAUSED:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                this.ad_paused = true;
                break;
            case globalThis.google.ima.AdEvent.Type.RESUMED:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                this.ad_paused = false;
                break;
            case globalThis.google.ima.AdEvent.Type.IMPRESSION:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.AD_PROGRESS:
                this.progress_bar_time_update(ad_event.getAdData());
                break;
            case globalThis.google.ima.AdEvent.Type.FIRST_QUARTILE:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.MIDPOINT:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.THIRD_QUARTILE:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.COMPLETE:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.CLICK:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                break;
            case globalThis.google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
                (0, log_1.logInfo)("VideoPlayer Event: " + ad_event.type);
                // Tell the SDK that the video is complete so the SDK can play any post-roll ads.
                this.ads_loader.contentComplete();
                // When all ads are done, destroy the ads manager
                this.destroyImaAdManagment();
                // reset video status
                this.resetVideoStatus();
                // Callback
                this.callback(true, false);
                break;
            case globalThis.google.ima.AdEvent.Type.AD_METADATA:
                (0, log_1.logInfo)("VideoPlayer Event" + globalThis.google.ima.AdEvent.Type.AD_METADATA + ": ", ad_event.getAdData());
                break;
        }
    };
    VideoInstance.prototype.isUrlOrVastTag = function (inputString) {
        // Regular expression for URL validation
        var urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
        // Regular expression for VAST tag validation
        var vastTagRegExp = /^</;
        if (urlRegex.test(inputString)) {
            return 'URL';
        }
        else if (vastTagRegExp.test(inputString)) {
            return 'VAST';
        }
        else {
            return false;
        }
    };
    // When an ad error occurs
    VideoInstance.prototype.adError = function (adErrorEvent) {
        var error_message = "";
        // Check if adErrorEvent is an instance of AdErrorEvent
        if (adErrorEvent instanceof Object && adErrorEvent.getError instanceof Function) {
            // Handle the ad error
            var errorObj = {
                message: adErrorEvent.getError().toString(),
                code: adErrorEvent.getError().getErrorCode(),
                type: adErrorEvent.type,
                vastErrorCode: adErrorEvent.getError().getVastErrorCode(),
                innerError: adErrorEvent.getError().getInnerError(),
            };
            error_message = JSON.stringify(errorObj);
            // Additional error handling logic goes here.
        }
        else if ((0, utils_1.isStr)(adErrorEvent)) {
            error_message = adErrorEvent;
        }
        // Create a new standard Error object
        var error = new Error(error_message);
        // Handle the error logging.
        (0, log_1.logError)(error);
        // Send error analytics
        Analytics_1.globalAnalytics.sendErrorAnalytics(error, 'VIDEO_AD_ERROR');
        if (this.slotObj.hasWinningBid()) {
            this.slotObj.winningBid = null;
        }
        if (this.slotObj.getWinningBid() &&
            this.slotObj.winningBid.demand_type === 'video') {
            this.ad_paused = true;
            this.ad_started = false;
            this.imaRequestAds();
        }
        else {
            // Remove video player from DOM
            this.deleteVideoPlayerFromDom((function () {
                this.callback(false, false);
            }).bind(this));
        }
    };
    // Delete video player from DOM
    VideoInstance.prototype.deleteVideoPlayerFromDom = function (cb) {
        // If the video player is not collapsed, wait for the transition to end before deleting it
        if (this.settings.collapse && !this.container.classList.contains('collapsed')) {
            // Add the collapsed class
            this.container.classList.add('collapsed');
            // Add event listener for transition end
            this.container.addEventListener('transitionend', (function () {
                // Remove the video player from the DOM
                this.deleteVideoPlayerFromDom(cb);
            }).bind(this), { once: true });
            return;
        }
        this.deleted = true;
        // Reset video status
        this.resetVideoStatus();
        // Destroy the IMA ad management
        this.destroyImaAdManagment();
        // Remove all event listeners
        this.remove_all_event_listeners();
        // Remove video wrapper from DOM
        this.video_wrapper.remove();
        // Remove the control bar from the video player
        this.container.classList.remove('bigcrunch-sticky-outstream-wrapper');
        this.container.classList.remove('bigcrunch-outstream-wrapper');
        // this.container.style.left = '';
        this.video_player.innerHTML = "";
        this.container.innerHTML = "";
        // Callback
        if (cb && typeof cb === 'function') {
            cb();
        }
    };
    VideoInstance.prototype.destroyImaAdManagment = function () {
        // Destroy ads_loader
        this.ads_loader.destroy();
        this.ads_loader = null;
        // Destroy ads_manager
        this.ads_manager.destroy();
        this.ads_manager = null;
        // Destroy ad_display_container
        this.ad_display_container.destroy();
        this.ad_display_container = null;
        // Set ad_playing to null
        this.ad_playing = null;
    };
    VideoInstance.prototype.resetVideoStatus = function () {
        this.ad_started = false;
        this.ad_paused = true;
        this.ad_finished = false;
        this.muted = true;
        this.deleted = false;
        this.can_play = false;
        this.initialized = false;
        this.time_stamp = null;
        this.ad_bar_interval = null;
    };
    // Load a script (used for loading the dash library)
    VideoInstance.prototype.loadScript = function (src) {
        return new Promise(function (resolve, reject) {
            var script = document.createElement('script');
            script.async = true;
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    };
    // Attach Control Bar to video player
    VideoInstance.prototype.addControlBar = function () {
        // If this specific control bar exists already, don't add it again
        if (this.control_bar)
            return;
        // This needs to be done in vanilla JS because it bugs out if you attach it as HTML
        // Create control_bar container
        var control_bar = document.createElement("div");
        control_bar.setAttribute("id", "control_bar");
        this.control_bar = control_bar;
        // Create bottom-controls container
        var bottom_controls = document.createElement("div");
        bottom_controls.setAttribute("class", "bottom-controls");
        // Add bottom-controls to control_bar container
        this.control_bar.appendChild(bottom_controls);
        // Create left-group container
        var left_group = document.createElement("div");
        left_group.setAttribute("class", "left-group");
        // Add left-group to bottom-controls container
        bottom_controls.appendChild(left_group);
        // Only include play/pause for testing
        if (__1.globalSettings.test.enabled) {
            // Create play button
            var play_button = document.createElement("button");
            play_button.setAttribute("aria-label", "play button");
            play_button.setAttribute("id", "play_button");
            play_button.setAttribute("type", "button");
            this.play_button = play_button;
            // Add play button to left-group container
            left_group.appendChild(this.play_button);
            // Add svg to play button
            this.play_button.innerHTML = this.play_icon;
        }
        // Create time span (container for time progress start and finish)
        var time = document.createElement("span");
        time.setAttribute("id", "time");
        // Add time to left-group container
        left_group.appendChild(time);
        /*
        // Create time progress start span
        const time_progress_start = document.createElement("span");
            time_progress_start.setAttribute("id", "time_progress_start");
            time_progress_start.textContent = "00:00";
        this.time_progress_start = time_progress_start;
        // Add time progress start to time span
        time.appendChild(this.time_progress_start);
        */
        // Create time progress finish span
        /*
        const time_progress_finish = document.createElement("span");
            time_progress_finish.setAttribute("id", "time_progress_finish");
            time_progress_finish.textContent = "00:00";
        this.time_progress_finish = time_progress_finish;
        // Add time progress finish to time span
        time.appendChild(time_progress_finish);
        */
        // Create time progress finish top span
        var time_progress_finish_top = document.createElement("span");
        time_progress_finish_top.setAttribute("id", "time_progress_finish_top");
        time_progress_finish_top.textContent = "00:00";
        this.time_progress_finish_top = time_progress_finish_top;
        // Add time progress finish to time span
        this.video_wrapper.appendChild(time_progress_finish_top);
        // Create volume group container
        var volume_group = document.createElement("span");
        volume_group.setAttribute("id", "volume-group");
        // Create Mute button
        var mute_button = document.createElement("button");
        mute_button.setAttribute("id", "mute_button");
        mute_button.setAttribute("aria-label", "mute button");
        mute_button.setAttribute("type", "button");
        this.mute_button = mute_button;
        // Add mute button to volume group container
        volume_group.appendChild(this.mute_button);
        // Add svg to play button
        if (this.muted)
            this.mute_button.innerHTML = this.volume_mute_icon;
        else
            this.mute_button.innerHTML = this.volume_up_icon;
        // Create label for volume bar
        var label = document.createElement("label");
        label.setAttribute("for", "volume_bar");
        label.classList.add("sr-only");
        label.textContent = "Volume Bar";
        // Add label to volume group container
        volume_group.appendChild(label);
        // Create input (actual slider) for volume bar
        var volume_bar = document.createElement("input");
        volume_bar.setAttribute("id", "volume_bar");
        volume_bar.setAttribute("name", "volume_bar");
        volume_bar.setAttribute("type", "range");
        volume_bar.setAttribute("min", "0");
        volume_bar.setAttribute("max", "1");
        volume_bar.setAttribute("step", "0.1");
        this.volume_bar = volume_bar;
        if (this.muted)
            this.volume_bar.setAttribute("value", "0");
        else
            this.volume_bar.setAttribute("value", "1");
        // Add input to volume group container
        volume_group.appendChild(this.volume_bar);
        var close_button = document.createElement("span");
        close_button.setAttribute("class", "close-btn-group");
        close_button.innerHTML = this.close_icon;
        this.close_button = close_button;
        // Add left-group to bottom-controls container
        this.video_wrapper.appendChild(close_button);
        // Add the control bar and volume group to the container div
        this.video_wrapper.appendChild(control_bar);
        this.video_wrapper.appendChild(volume_group);
        this.setup_control_bar_events();
    };
    // Setup events to make control bar work
    VideoInstance.prototype.setup_control_bar_events = function () {
        // Mute button logic
        if (this.mute_button)
            this.mute_button.addEventListener('click', this.mute_button_functionality.bind(this));
        // Volume bar changing
        if (this.volume_bar)
            this.volume_bar.addEventListener('change', this.volume_bar_change_functionality.bind(this));
        // Play/Pause button logic
        if (this.play_button)
            this.play_button.addEventListener('click', this.play_pause_button_functionality.bind(this));
        // Close button logic
        if (this.close_button)
            this.close_button.addEventListener('click', this.close_button_functionality.bind(this));
    };
    // Remove all event listeners
    VideoInstance.prototype.remove_all_event_listeners = function () {
        // Remove mute button event
        if (this.mute_button)
            this.mute_button.removeEventListener('click', this.mute_button_functionality.bind(this));
        // Remove volume bar changing event
        if (this.volume_bar)
            this.volume_bar.removeEventListener('change', this.volume_bar_change_functionality.bind(this));
        // Remove play/pause button event
        if (this.play_button)
            this.play_button.removeEventListener('click', this.play_pause_button_functionality.bind(this));
        // Remove Close button logic
        if (this.close_button)
            this.close_button.addEventListener('click', this.close_button_functionality.bind(this));
    };
    // Play/Pause button functionality
    VideoInstance.prototype.play_pause_button_functionality = function () {
        // If video is paused, play it and change the button to pause
        if (this.ad_paused == true) {
            this.can_play = true;
            this.playAds();
            // If video is playing, pause it and change the button to play
        }
        else if (this.ad_paused == false) {
            this.can_play = false;
            // this.video_player.pause();
            // Change the button to play
            if (this.play_button) {
                this.play_button.innerHTML = this.play_icon;
            }
            if (this.ads_manager) {
                this.ads_manager.pause();
                this.ad_paused = true;
            }
        }
    };
    // Volume bar changing functionality
    VideoInstance.prototype.volume_bar_change_functionality = function () {
        // Set the volume of the video to the volume bar value
        this.video_player.volume = Number(this.volume_bar.value);
        this.ads_manager.setVolume(Number(this.volume_bar.value));
        // If volume is greater than 0, unmute the video
        if (Number(this.volume_bar.value) > 0) {
            this.muted = false;
            this.video_player.muted = false;
            this.mute_button.innerHTML = this.volume_up_icon;
            // If volume is 0, mute the video
        }
        else {
            this.mute_button.innerHTML = this.volume_mute_icon;
            this.muted = true;
            this.video_player.muted = true;
        }
    };
    // Mute button functionality
    VideoInstance.prototype.mute_button_functionality = function () {
        if (this.video_player.muted == true || this.muted == true) {
            this.muted = false;
            this.video_player.muted = false;
            this.mute_button.innerHTML = this.volume_up_icon;
            this.volume_bar.value = this.video_player.volume.toString();
            this.ads_manager.setVolume(Number(this.volume_bar.value));
            // Muting
        }
        else {
            this.muted = true;
            this.video_player.muted = true;
            this.mute_button.innerHTML = this.volume_mute_icon;
            this.volume_bar.value = '0';
            this.ads_manager.setVolume(0);
        }
    };
    // Close button functionality
    VideoInstance.prototype.close_button_functionality = function () {
        // Remove video player from DOM
        this.deleteVideoPlayerFromDom((function () {
            this.callback(true, true);
        }).bind(this));
    };
    // Update progress bar as time moves forward
    VideoInstance.prototype.progress_bar_time_update = function (adData) {
        var _a, _b, _c, _d, _e;
        // If the video is deleted, don't update the progress bar
        if (this.deleted)
            return;
        // Update progress bar
        if (this.ads_manager) {
            var duration = adData.adBreakDuration || adData.duration || this.ad_playing.getDuration();
            var time_left = this.ads_manager.getRemainingTime();
            var time_stamp = duration - time_left;
            time_stamp = time_stamp > 0 ? ((time_stamp <= duration) ? time_stamp : duration) : 0;
            // round time_stamp to nearest 0.01
            time_stamp = Math.round(time_stamp * 100) / 100;
            this.time_stamp = time_stamp;
            // If video played more then 2 seconds
            if (this.time_stamp > 2 && !((_c = (_b = (_a = this.slotObj) === null || _a === void 0 ? void 0 : _a.displayedBid) === null || _b === void 0 ? void 0 : _b.viewability) === null || _c === void 0 ? void 0 : _c.pixel_sent)) {
                //dispaly close button
                this.close_button.style.display = 'flex';
                // Send viewability analytics
                (_e = (_d = this.slotObj) === null || _d === void 0 ? void 0 : _d.displayedBid) === null || _e === void 0 ? void 0 : _e.setVideoViewabilityMet();
            }
            /*
            const duration = this.ad_playing.getDuration();
            let time_stamp = duration - time_left;
            time_stamp = time_stamp > 0 ? ((time_stamp <= duration) ? time_stamp : duration) : 0;
            // round time_stamp to nearest 0.01
            time_stamp = Math.round(time_stamp * 100) / 100;
            // Update progress bar
            this.time_progress_start.innerHTML = this.convert_video_time(Math.floor(time_stamp));
            this.time_progress_finish.innerHTML = this.convert_video_time(Math.ceil(time_left));
            */
            this.time_progress_finish_top.innerHTML = this.convert_video_time(Math.floor(time_left));
        }
        return;
    };
    // Changes display of video time to MM:SS
    VideoInstance.prototype.convert_video_time = function (time) {
        var return_time, hours, minutes, seconds;
        seconds = time;
        minutes = Math.floor(time / 60);
        seconds -= (minutes * 60);
        if (minutes > 60) {
            hours = Math.floor(minutes / 60);
            minutes -= (hours * 60);
        }
        if (minutes < 10)
            minutes = "0" + minutes;
        if (seconds < 10)
            seconds = "0" + seconds;
        return_time = seconds;
        if (minutes > 0)
            return_time = minutes + ":" + seconds;
        if (hours && hours > 0)
            return_time = hours + ":" + time;
        return return_time;
    };
    return VideoInstance;
}());
exports.VideoInstance = VideoInstance;
