"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setConsentConfig = exports.storeConsentData = exports.loadIfMissing = exports.lookupIabConsent = void 0;
/**
 * This module adds GPP consentManagement support.  It interacts with
 * supported CMPs (Consent Management Platforms) to grab the user's consent information
 * and make it available for any GPP supported adapters to read/pass this information to
 * their system and for various other features/modules.
 */
var log_1 = require("../../utils/log");
var utils_1 = require("../../utils/utils");
var cmpClient_1 = require("./cmpClient");
var ConsentHandler_1 = require("../../classes/consent/ConsentHandler");
var ConsentHandler_2 = require("../../classes/consent/ConsentHandler");
var GPP_10 = '1.0';
var GPP_11 = '1.1';
var DEFAULT_CMP_VERSION = GPP_11;
var DEFAULT_CONSENT_TIMEOUT = 10000;
var consentData;
var consentTimeout;
var GPPError = /** @class */ (function () {
    function GPPError(message, arg) {
        this.message = message;
        this.args = arg == null ? [] : [arg];
    }
    return GPPError;
}());
var GPPClient = /** @class */ (function () {
    function GPPClient(cmpVersion, cmp) {
        var _a;
        var _this = this;
        this.initialized = false;
        this.pending = [];
        this.cmpVersion = cmpVersion;
        this.cmp = cmp;
        _a = [0, 1].map(function (slot) { return function (result) {
            while (_this.pending.length) {
                _this.pending.pop()[slot](result);
            }
        }; }), this.resolve = _a[0], this.reject = _a[1];
    }
    GPPClient.ping = function (mkCmp) {
        if (mkCmp === void 0) { mkCmp = cmpClient_1.cmpClient; }
        var cmpOptions = {
            apiName: '__gpp',
            apiArgs: ['command', 'callback', 'parameter'], // do not pass version - not clear what it's for (or what we should use)
        };
        // in 1.0, 'ping' should return pingData but ignore callback;
        // in 1.1 it should not return anything but run the callback
        // the following looks for either - but once the version is known, produce a client that knows whether the
        // rest of the interactions should pick return values or pass callbacks
        var probe = mkCmp(__assign(__assign({}, cmpOptions), { mode: cmpClient_1.MODE_RETURN }));
        return new Promise(function (resolve, reject) {
            // CMP not found
            if (probe == null) {
                reject(new Error('GPP CMP not found'));
                return;
            }
            var done = false;
            // some CMPs do both return value and callbacks - avoid repeating log messages
            // wrap the callback to resolve the promise
            var pong = function (result, success) {
                if (done)
                    return;
                if (success != null && !success) {
                    reject(result);
                    return;
                }
                if (result == null)
                    return;
                done = true;
                var cmpVersion = (result === null || result === void 0 ? void 0 : result.gppVersion) || DEFAULT_CMP_VERSION;
                var mode;
                var client;
                if (cmpVersion == GPP_10) {
                    mode = cmpClient_1.MODE_MIXED;
                    client = new GPP10Client(cmpVersion, mkCmp(__assign(__assign({}, cmpOptions), { mode: mode })));
                }
                else if (cmpVersion == GPP_11) {
                    mode = cmpClient_1.MODE_CALLBACK;
                    client = new GPP11Client(cmpVersion, mkCmp(__assign(__assign({}, cmpOptions), { mode: mode })));
                }
                resolve([client, result]);
            };
            probe({
                command: 'ping',
                callback: pong
            }).then(function (res) { return pong(res, true); })
                .catch(function (err) { return reject(new Error("GPP CMP ping failed: ".concat(err))); });
        });
    };
    /**
     * initialize this client - update consent data if already available,
     * and set up event listeners to also update on CMP changes
     *
     * @param pingData
     * @returns {Promise<{}>} a promise to GPP consent data
     */
    GPPClient.prototype.init = function (pingData) {
        var _this = this;
        var ready = this.updateWhenReady(pingData);
        if (!this.initialized) {
            this.initialized = true;
            this.cmp({
                command: 'addEventListener',
                callback: function (event, success) {
                    var _a;
                    if (success != null && !success) {
                        _this.reject(new GPPError('Received error response from CMP', event));
                    }
                    else if (((_a = event === null || event === void 0 ? void 0 : event.pingData) === null || _a === void 0 ? void 0 : _a.cmpStatus) === 'error') {
                        _this.reject(new GPPError('CMP status is "error"; please check CMP setup', event));
                    }
                    else if (_this.isCMPReady((event === null || event === void 0 ? void 0 : event.pingData) || {}) && _this.events.includes(event === null || event === void 0 ? void 0 : event.eventName)) {
                        _this.resolve(_this.updateConsent(event.pingData));
                    }
                }
            });
        }
        return ready;
    };
    GPPClient.prototype.refresh = function () {
        return this.cmp({ command: 'ping' }).then(this.updateWhenReady.bind(this));
    };
    /**
     * Retrieve and store GPP consent data.
     *
     * @param pingData
     * @returns {Promise<{}>} a promise to GPP consent data
     */
    GPPClient.prototype.updateConsent = function (pingData) {
        return this.getGPPData(pingData).then(function (data) {
            if (data == null || (0, utils_1.isEmpty)(data)) {
                throw new GPPError('Received empty response from CMP', data);
            }
            return processCmpData(data, { onSuccess: function () { return data; }, onError: function () { return data; } });
        }).then(function (data) {
            (0, log_1.logInfo)('Retrieved GPP consent from CMP:', data);
            return data;
        });
    };
    /**
     * Return a promise to GPP consent data, to be retrieved the next time the CMP signals it's ready.
     *
     * @returns {Promise<{}>}
     */
    GPPClient.prototype.nextUpdate = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.pending.push([resolve, reject]);
        });
    };
    /**
     * Return a promise to GPP consent data, to be retrieved immediately if the CMP is ready according to `pingData`,
     * or as soon as it signals that it's ready otherwise.
     *
     * @param pingData
     * @returns {Promise<{}>}
     */
    GPPClient.prototype.updateWhenReady = function (pingData) {
        return this.isCMPReady(pingData) ? this.updateConsent(pingData) : this.nextUpdate();
    };
    return GPPClient;
}());
// eslint-disable-next-line no-unused-vars
var GPP10Client = /** @class */ (function (_super) {
    __extends(GPP10Client, _super);
    function GPP10Client(cmpVersion, cmp) {
        var _this = _super.call(this, cmpVersion, cmp) || this;
        _this.events = ['sectionChange', 'cmpStatus'];
        return _this;
    }
    GPP10Client.prototype.isCMPReady = function (pingData) {
        (0, log_1.logInfo)('GPP CMP status 1.0:', pingData);
        return pingData.cmpStatus === 'loaded';
    };
    GPP10Client.prototype.getGPPData = function (pingData) {
        var _this = this;
        var parsedSections = Promise.all((pingData.supportedAPIs || pingData.apiSupport || []).map(function (api) { return _this.cmp({
            command: 'getSection',
            parameter: api
        }).catch(function (err) {
            (0, log_1.logWarn)("Could not retrieve GPP section '".concat(api, "'"), err);
        }).then(function (section) { return [api, section]; }); })).then(function (sections) {
            // parse single section object into [core, gpc] to uniformize with 1.1 parsedSections
            return Object.fromEntries(sections.filter(function (_a) {
                var val = _a[1];
                return val != null;
            })
                .map(function (_a) {
                var api = _a[0], section = _a[1];
                var subsections = [
                    Object.fromEntries(Object.entries(section).filter(function (_a) {
                        var k = _a[0];
                        return k !== 'Gpc';
                    }))
                ];
                if (section.Gpc != null) {
                    subsections.push({
                        SubsectionType: 1,
                        Gpc: section.Gpc
                    });
                }
                return [api, subsections];
            }));
        });
        return Promise.all([
            this.cmp({ command: 'getGPPData' }),
            parsedSections
        ]).then(function (_a) {
            var gppData = _a[0], parsedSections = _a[1];
            return Object.assign({}, gppData, { parsedSections: parsedSections });
        });
    };
    return GPP10Client;
}(GPPClient));
// eslint-disable-next-line no-unused-vars
var GPP11Client = /** @class */ (function (_super) {
    __extends(GPP11Client, _super);
    function GPP11Client(cmpVersion, cmp) {
        var _this = _super.call(this, cmpVersion, cmp) || this;
        _this.events = ['sectionChange', 'signalStatus'];
        return _this;
    }
    GPP11Client.prototype.isCMPReady = function (pingData) {
        (0, log_1.logInfo)('GPP CMP status 1.1:', pingData);
        if (pingData.gppVersion == GPP_10) {
            (0, log_1.logWarn)("GPP CMP version is not 1.1. (".concat(GPP_10, ")"));
            return this.runGpp10Client('isCMPReady', pingData);
        }
        return pingData.signalStatus === 'ready';
    };
    GPP11Client.prototype.getGPPData = function (pingData) {
        if (pingData.gppVersion == GPP_10) {
            (0, log_1.logWarn)("GPP CMP version is not 1.1. (".concat(GPP_10, ")"));
            return this.runGpp10Client('getGPPData', pingData);
        }
        return Promise.resolve(pingData);
    };
    GPP11Client.prototype.runGpp10Client = function (command, pingData) {
        if (!this.gpp10Client) {
            this.gpp10Client = new GPP10Client(GPP_10, this.cmp);
        }
        return this.gpp10Client[command](pingData);
    };
    return GPP11Client;
}(GPPClient));
/**
 * Look up consent data and store it in the `consentData` global as well as `adapterManager.js`' gdprDataHandler.
 *
 * @param cb A callback that takes: a boolean that is true if the auction should be canceled; an error message and extra
 * error arguments that will be undefined if there's no error.
 */
function loadConsentData(cb) {
    var isDone = false;
    var timer = null;
    var fail_count = 0;
    function done(consentData, shouldCancelAuction, errMsg) {
        var extraArgs = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            extraArgs[_i - 3] = arguments[_i];
        }
        if (timer != null) {
            clearTimeout(timer);
        }
        isDone = true;
        (0, log_1.logInfo)('GPP CMP lookup complete.', consentData);
        ConsentHandler_1.gppConsentHandler.setConsentData(consentData);
        if (typeof cb === 'function') {
            cb.apply(void 0, __spreadArray([shouldCancelAuction, errMsg], extraArgs, false));
        }
    }
    var callbacks = {
        onSuccess: function (data) { return done(data, false, ""); },
        onError: function (msg) {
            var extraArgs = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                extraArgs[_i - 1] = arguments[_i];
            }
            if (fail_count < 10) {
                fail_count++;
                setTimeout(function () {
                    lookupIabConsent(callbacks);
                }, 500);
            }
            else {
                done.apply(void 0, __spreadArray([null, true, msg], extraArgs, false));
            }
        }
    };
    lookupIabConsent(callbacks);
    if (!isDone) {
        var onTimeout = function () {
            (0, log_1.logWarn)("GPP CMP lookup timed out after ".concat(consentTimeout, " ms"));
            var continueToAuction = function (data) {
                done(data, false, 'GPP CMP did not load, continuing auction...');
            };
            processCmpData(consentData, {
                onSuccess: continueToAuction,
                onError: function () { return continueToAuction(storeConsentData({})); }
            });
        };
        if (consentTimeout === 0) {
            onTimeout();
        }
        else {
            timer = setTimeout(onTimeout, consentTimeout);
        }
    }
    // add eventListener for event that country code was recieved
    document.addEventListener('BcConsentCountryCode', function (event) {
        if (!['US', 'EU', 'CA'].includes(event.detail) && !isDone) {
            done({
                gppString: '',
                applicableSections: [],
                parsedSections: {}
            }, false, '');
        }
    });
    // add eventListener for event that country code was recieved
    document.addEventListener('BcConsentRegionCode', function (event) {
        if (event.detail.country === 'US' &&
            !ConsentHandler_2.US_REGIONS.includes(event.detail.region) &&
            !isDone) {
            done({
                gppString: '',
                applicableSections: [],
                parsedSections: {}
            }, false, '');
        }
    });
}
/**
 * This function handles interacting with an IAB compliant CMP to obtain the consent information of the user.
 * Given the async nature of the CMP's API, we pass in acting success/error callback functions to exit this function
 * based on the appropriate result.
 * @param {function({})} onSuccess acts as a success callback when CMP returns a value; pass along consentObjectfrom CMP
 * @param {function(string, ...{}?)} cmpError acts as an error callback while interacting with CMP; pass along an error message (string) and any extra error arguments (purely for logging)
 */
function lookupIabConsent(_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError;
    GPPClient.ping().then(function (_a) {
        var client = _a[0], gppDataPm = _a[1];
        return client.init(gppDataPm);
    }).then(function (gppData) {
        onSuccess(gppData);
    }).catch(function (err) {
        onError(err);
    });
}
exports.lookupIabConsent = lookupIabConsent;
/**
 * Like `loadConsentData`, but cache and re-use previously loaded data.
 * @param cb
 */
function loadIfMissing(cb) {
    if (consentData && consentData.gppString) {
        (0, log_1.logInfo)('GPP User consent information already known.  Pulling internally stored information...');
        cb(false);
    }
    else {
        loadConsentData(cb);
    }
}
exports.loadIfMissing = loadIfMissing;
function processCmpData(consentData, _a) {
    var onSuccess = _a.onSuccess, onError = _a.onError;
    if (((consentData === null || consentData === void 0 ? void 0 : consentData.applicableSections) != null && !Array.isArray(consentData.applicableSections)) ||
        ((consentData === null || consentData === void 0 ? void 0 : consentData.gppString) != null && !(0, utils_1.isStr)(consentData.gppString)) ||
        ((consentData === null || consentData === void 0 ? void 0 : consentData.parsedSections) != null && !(0, utils_1.isPlainObject)(consentData.parsedSections))) {
        onError('GPP CMP returned unexpected value during lookup process.', consentData);
        // throw new GPPError('CMP returned unexpected value during lookup process.', consentData);
    }
    consentData = storeConsentData(consentData);
    (0, log_1.logInfo)('GPP Consent Data.', consentData);
    onSuccess(consentData);
    return consentData;
}
/**
 * Stores CMP data locally in module to make information available in adaptermanager.js for later in the auction
 * @param {{}} gppData the result of calling a CMP's `getGPPData` (or equivalent)
 * @param {{}} sectionData map from GPP section name to the result of calling a CMP's `getSection` (or equivalent)
 */
function storeConsentData(gppData) {
    consentData = {
        gppString: gppData === null || gppData === void 0 ? void 0 : gppData.gppString,
        applicableSections: (gppData === null || gppData === void 0 ? void 0 : gppData.applicableSections.filter(function (sid) { return sid !== -1; })) || [],
        parsedSections: (gppData === null || gppData === void 0 ? void 0 : gppData.parsedSections) || {},
        gppData: gppData
    };
    // gppDataHandler.setConsentData(gppData);
    return consentData;
}
exports.storeConsentData = storeConsentData;
/**
 * A configuration function that initializes some module variables, as well as add a hook into the requestBids function
 * @param {{cmp:string, timeout:number, allowAuctionWithoutConsent:boolean, defaultGdprScope:boolean}} config required; consentManagement module config settings; cmp (string), timeout (int), allowAuctionWithoutConsent (boolean)
 */
function setConsentConfig(config) {
    config = config && config.gpp;
    if (!config || typeof config !== 'object') {
        (0, log_1.logWarn)('consentManagement.gpp config not defined, exiting consent manager module');
        return;
    }
    if ((0, utils_1.isNumber)(config.timeout)) {
        consentTimeout = config.timeout;
    }
    else {
        consentTimeout = DEFAULT_CONSENT_TIMEOUT;
        (0, log_1.logInfo)("consentManagement.gpp config did not specify timeout.  Using system default setting (".concat(DEFAULT_CONSENT_TIMEOUT, ")."));
    }
    (0, log_1.logInfo)('consentManagement.gpp module has been activated...');
    loadConsentData(function () { }); // immediately look up consent data to make it available without requiring an auction
}
exports.setConsentConfig = setConsentConfig;
